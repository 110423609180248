import React from 'react';

const UndefinedPage = () => {
  return (
    <div>
      <h2>No page found.</h2>
    </div>
  )
}

export default UndefinedPage